<template>
    <transition>
        <DxDataGrid
            class="grid-box"
			key-expr="scheduleId"
            :data-source="contentData"
            :show-borders="false"
            :show-column-headers="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="false"
            :hover-state-enabled="true"
            :word-wrap-enabled="true"
            :no-data-text="this.$_msgContents('CMN_NO_DATA')"
            width="100%"
            height="100%"
        >
            <DxLoadPanel :enabled="true" />
            <DxScrolling mode="virtual"/>
            <DxFilterRow :visible="true" />
            <DxHeaderFilter :visible="true"/>

            <DxColumn
                caption="상담사"
                data-field="agtid"
                :allowEditing="false"
                :allow-sorting="false"
                alignment="center"
                :visible="true"
                :allowFiltering="false"
                :allowHeaderFiltering="false"
                :calculate-cell-value="rowData => `${rowData.agtNm} [${rowData.agtid}]`"
            />
            <DxColumn
                caption="기준일"
                data-field="workDt"
                :allowEditing="false"
                :allow-sorting="true"
                alignment="center"
                :visible="true"
                :allowFiltering="true"
                :allowHeaderFiltering="true"
                :calculate-display-value="rowData => formatDate(rowData.workDt, 'YYYY-MM-DD', 'YYYY-MM-DD')"
            />
            <DxColumn
                caption="근무유형"
                data-field="workNm"
                :allowEditing="false"
                :allow-sorting="true"
                alignment="center"
                :visible="true"
                :allowFiltering="true"
                :allowHeaderFiltering="true"
            >
            </DxColumn>
            <DxColumn
                caption="근무스케줄"
                data-field="workStartTime"
                :allowEditing="false"
                :allow-sorting="true"
                alignment="center"
                :visible="true"
                :allowFiltering="true"
                :allowHeaderFiltering="true"
                :calculate-display-value="rowData => `${rowData.workStartTime} ~ ${rowData.workEndTime}`"
            />
            <DxColumn
                caption="로그인시간"
                data-field="firstLoginDt"
                :allowEditing="false"
                :allow-sorting="true"
                alignment="center"
                :visible="true"
                :allowFiltering="true"
                :allowHeaderFiltering="false"
            />
            <DxColumn
                caption="로그아웃시간"
                data-field="lastLogoutDt"
                :allowEditing="false"
                :allow-sorting="true"
                alignment="center"
                :visible="true"
                :allowFiltering="true"
                :allowHeaderFiltering="false"
            />
            <DxColumn
                caption="출결정보"
                data-field="attendanceState"
                :allowEditing="false"
                :allow-sorting="true"
                alignment="center"
                :visible="true"
                :allowFiltering="true"
                :allowHeaderFiltering="true"
            />
        </DxDataGrid>
    </transition>
</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxLookup,
    DxScrolling,
    DxLoadPanel,
    DxFilterRow,
    DxHeaderFilter
} from 'devextreme-vue/data-grid';
import {formatDate} from "@/plugins/common-lib";

let vm;

export default {
    components: {
		DxDataGrid,
        DxColumn,
        DxLoadPanel,
        DxLookup,
        DxScrolling,
        DxFilterRow,
        DxHeaderFilter,
	},
	props: {
		contentData: Array,
	},
	watch: {},
	data() {
		return {
            codeMap: {},
			codes: {
				evalProcessCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId'
				}
			},
			config: {
				stylingMode: 'outlined',
			},
            gridDataSource: null,
		};
	},
	computed: {},
	methods: {
    formatDate,
        /** @description : 코드 초기화 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_eval_process');
		},
		/** @description : 라이프사이클 created 호출되는 메서드 */
		createdData() {
            vm = this;

            this.initCodeMap().then(() => {
				this.codes.evalProcessCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_process'], 2);
			});
            this.gridDataSource = this.contentData;
		},
		/** @description : 라이프사이클 mounted 호출되는 메서드 */
		mountedData() {
			
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>